import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage'; // Import Firebase Storage

// Firebase configuration
export const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
});


export const storage = getStorage(firebaseApp); // Initialize Firebase Storage

export default storage;